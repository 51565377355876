exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-properties-js": () => import("./../../../src/pages/properties.js" /* webpackChunkName: "component---src-pages-properties-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-property-template-js": () => import("./../../../src/templates/propertyTemplate.js" /* webpackChunkName: "component---src-templates-property-template-js" */),
  "component---src-templates-rental-template-js": () => import("./../../../src/templates/rentalTemplate.js" /* webpackChunkName: "component---src-templates-rental-template-js" */),
  "component---src-templates-team-template-js": () => import("./../../../src/templates/teamTemplate.js" /* webpackChunkName: "component---src-templates-team-template-js" */)
}

