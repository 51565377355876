import React, { useState, useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import HamburgerIcon from "../assets/images/hamburger-icon.svg"
import FacebookIcon from "../assets/images/ifacebook.svg"
import InstagramIcon from "../assets/images/iinstagram.svg"
import LinkedInIcon from "../assets/images/ilinkedin.svg"
import YoutubeIcon from "../assets/images/iyoutube.svg"
import LogoImage from "../assets/images/LogoWN.svg"
import LogoImageDark from "../assets/images/LogoWNDark.svg"
import CrossIcon from "../assets/images/Plus.svg"
import SearchIcon from "../assets/images/search-icon.svg"
import Link from "../utils/link"

const Header = ({ pathname }) => {
  const [isVisible, setIsVisible] = useState(false)

  const isLight = [
    "/contact-us",
    "/appraisals",
    "/team",
    "/terms-and-conditions",
    "/privacy-policy",
    "/thank-you",
  ].some((link) => pathname.includes(link))

  const data = useStaticQuery(graphql`
    query HeaderContent {
      wpPost(slug: { eq: "header" }) {
        acf_header {
          about
          address
          copy
          design
          navigationLeft {
            link {
              title
              url
            }
          }
          navigationRight {
            link {
              title
              url
            }
          }
          phone
          rentalsemail
          salesemail
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
  `)

  const headerContent = data.wpPost.acf_header

  const closeMenu = () => {
    setIsVisible(false)
    const body = document.querySelector("body")
    body.classList.remove("scroll-prevent")
  }

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
    const body = document.querySelector("body")
    body.classList.toggle("scroll-prevent")
  }

  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 55) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }

  return (
    <header className={hasScrolled ? "has-scrolled" : ""}>
      <div
        className={
          isVisible ? " header__container header__dark" : "header__container"
        }
      >
        <Link
          to="/properties"
          className={`header__container__search ${
            isLight && !hasScrolled && !isVisible ? "is-light" : ""
          }`}
          onClick={() => closeMenu()}
        >
          <img src={SearchIcon} alt="hamburger" />
        </Link>

        <Link
          to="/"
          title="Warlimont & Nutt"
          className={`header__container__logo ${
            isLight && !hasScrolled && !isVisible ? "is-light" : ""
          }`}
          onClick={() => closeMenu()}
        >
          {isLight && !hasScrolled && !isVisible ? (
            <img src={LogoImageDark} alt="Warlimont & Nutt Logo" />
          ) : (
            <img src={LogoImage} alt="Warlimont & Nutt Logo" />
          )}
        </Link>

        <button
          className={`header__container__hamburger 
            ${isVisible ? "header__container__cross" : ""} 
            ${isLight && !hasScrolled && !isVisible ? "is-light" : ""}
          `}
          onClick={toggleIsVisible}
        >
          <img src={isVisible ? CrossIcon : HamburgerIcon} alt="hamburger" />
        </button>
      </div>
      <div className={isVisible ? "header__flyout" : "d-none"}>
        <div className="header__flyout__top">
          <div className="header__flyout__top__left">
            <div className="header-nav">
              {headerContent.navigationLeft.map((item, i) => (
                <a
                  key={i}
                  href={item.link.url}
                  onClick={() => {
                    toggleIsVisible()
                  }}
                  id="filter-links"
                  data-label={item.link.title}
                >
                  <p>{item.link.title}</p>
                </a>
              ))}
            </div>
            <div className="header-nav-second">
              {headerContent.navigationRight.map((item, i) => (
                <Link
                  key={i}
                  to={item.link.url}
                  onClick={() => toggleIsVisible()}
                  id="filter-links"
                  data-label={item.link.title}
                >
                  <p>{item.link.title}</p>
                </Link>
              ))}
            </div>
          </div>
          <div className="header__flyout__top__right">
            <div className="header__flyout__top__right__address-contact">
              <div className="header-address">
                <h2 className="header-address__label">Address</h2>
                <div
                  className="header-address__address"
                  dangerouslySetInnerHTML={{ __html: headerContent.address }}
                />
              </div>
              <div className="header-contact">
                <h2 className="header-contact__label">Call us</h2>
                <Link to={`tel:${headerContent.phone}`}>
                  <p className="header-contact__number">
                    {headerContent.phone}
                  </p>
                </Link>
              </div>
            </div>
            <div className="header__flyout__top__right__email-socials">
              <div className="header-email">
                <h2 className="header-email__label">Email Us</h2>
                <Link
                  to={`mailto:${headerContent.salesemail}`}
                  onClick={toggleIsVisible}
                >
                  <p className="header-email__mail">
                    {headerContent.salesemail}
                  </p>
                </Link>
                <Link
                  to={`mailto:${headerContent.salesemail}`}
                  onClick={toggleIsVisible}
                >
                  <p className="header-email__mail">
                    {headerContent.rentalsemail}
                  </p>
                </Link>
              </div>
              <div className="header-socials">
                <h2 className="header-socials__label">Socials</h2>
                <span>
                  <Link to={headerContent.facebook} onClick={toggleIsVisible}>
                    <img src={FacebookIcon} alt="facebook" />
                  </Link>
                  <Link to={headerContent.instagram} onClick={toggleIsVisible}>
                    <img src={InstagramIcon} alt="instagram" />
                  </Link>
                  <Link to={headerContent.linkedin} onClick={toggleIsVisible}>
                    <img src={LinkedInIcon} alt="linkedin" />
                  </Link>
                  <Link to={headerContent.youtube} onClick={toggleIsVisible}>
                    <img src={YoutubeIcon} alt="youtube" />
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="header__flyout__line"></div>
        <div className="header__flyout__bottom">
          <p dangerouslySetInnerHTML={{ __html: headerContent.address }} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default Header
