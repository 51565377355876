import React from "react"

import PropTypes from "prop-types"

import Footer from "../components/footer"
import Header from "../components/header"

import "../assets/scss/main.scss"

import "./index.scss"

const Layout = ({ children, location }) => {
  return (
    <main
      className={
        location.pathname === "/about-us" ||
        location.pathname === "/about-us/" ||
        location.pathname === "/property-management" ||
        location.pathname === "/property-management/" ||
        location.pathname === "/careers" ||
        location.pathname === "/careers/"
          ? "dark-background"
          : ""
      }
    >
      <Header pathname={location.pathname} />
      {children}
      <Footer />
      <div id="bf-revz-widget-1249640211"></div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
