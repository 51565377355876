import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import FacebookIcon from "../assets/images/ifacebook.svg"
import InstagramIcon from "../assets/images/iinstagram.svg"
import LinkedInIcon from "../assets/images/ilinkedin.svg"
import YoutubeIcon from "../assets/images/iyoutube.svg"
import Logo from "../assets/images/LogoWN.svg"
import Link from "../utils/link"

const NavFirst = ({ links }) => {
  return (
    <div className="footer-nav">
      {links.map((item, i) => (
        <Link
          key={i}
          to={item.link.url}
          id="filter-links"
          data-label={item.link.title}
        >
          <p>{item.link.title}</p>
        </Link>
      ))}
    </div>
  )
}

NavFirst.propTypes = {
  links: PropTypes.array,
}

const NavSecond = ({ links }) => {
  return (
    <div className="footer-nav-second">
      {links.map((item, i) => (
        <Link key={i} to={item.link.url}>
          <p>{item.link.title}</p>
        </Link>
      ))}
    </div>
  )
}

NavSecond.propTypes = {
  links: PropTypes.array,
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterContent {
      wpPost(slug: { eq: "footer" }) {
        acf_header {
          about
          address
          copy
          design
          navigationLeft {
            link {
              title
              url
            }
          }
          navigationRight {
            link {
              title
              url
            }
          }
          policyLink {
            link {
              title
              url
            }
          }
          phone
          rentalsemail
          salesemail
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
  `)

  const footerContent = data.wpPost.acf_header

  return (
    <footer className="footer">
      <div className="footer__container">
        <Link to="/">
          <img src={Logo} alt="logo" className="footer__container__logo" />
        </Link>
        <div className="footer__container__top">
          <div className="footer__container__top__address-contact">
            <div className="footer-address">
              <h2 className="footer-address__label">Address</h2>
              <div
                className="footer-address__address"
                dangerouslySetInnerHTML={{ __html: footerContent.address }}
              />
            </div>
            <div className="footer-contact">
              <h2 className="footer-contact__label">Call us</h2>
              <Link to={`tel:${footerContent.phone}`}>
                <p className="footer-contact__number">{footerContent.phone}</p>
              </Link>
            </div>
          </div>
          <div className="footer__container__top__links-desktop">
            <NavFirst links={footerContent.navigationLeft} />
          </div>
          <div className="footer__container__top__email-socials">
            <div className="footer-email">
              <h2 className="footer-email__label">Email Us</h2>
              <Link to={`mailto:${footerContent.salesemail}`}>
                <p className="footer-email__mail">{footerContent.salesemail}</p>
              </Link>
              <Link to={`mailto:${footerContent.rentalsemail}`}>
                <p className="footer-email__mail">
                  {footerContent.rentalsemail}
                </p>
              </Link>
            </div>
            <div className="footer-socials">
              <h2 className="footer-socials__label">Socials</h2>
              <span>
                <Link to={footerContent.facebook}>
                  <img src={FacebookIcon} alt="facebook" />
                </Link>
                <Link to={footerContent.instagram}>
                  <img src={InstagramIcon} alt="instagram" />
                </Link>
                <Link to={footerContent.linkedin}>
                  <img src={LinkedInIcon} alt="linkedin" />
                </Link>
                <Link to={footerContent.youtube}>
                  <img src={YoutubeIcon} alt="youtube" />
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="footer__container__line-one"></div>
        <div className="footer__container__mobile-links">
          <NavFirst links={footerContent.navigationLeft} />
          <NavSecond links={footerContent.navigationRight} />
        </div>
        <div className="footer__container__line-two"></div>
        <div className="footer__container__bottom">
          <div className="footer__container__bottom__terms-privacy">
            {footerContent.policyLink.map((item, i) => (
              <Link key={i} to={item.link?.url}>
                <p dangerouslySetInnerHTML={{ __html: item.link?.title }} />
              </Link>
            ))}
          </div>
          <div className="footer__container__bottom__links-desktop-more">
            <NavSecond links={footerContent.navigationRight} />
          </div>
          <div className="footer__container__bottom__info">
            <p>{footerContent.copy}</p>
            <p>
              
            </p>
          </div>
        </div>
        <div className="footer__container__about">
          <p dangerouslySetInnerHTML={{ __html: footerContent.about }} />
        </div>
      </div>
    </footer>
  )
}

export default Footer
